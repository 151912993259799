<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Arqueo de Caja - Nuevo</strong>
          </CCardHeader>
          <CCardBody>

                <div class="w-100" v-if="state == 1">

                    <b-form id="Form" @submit.prevent="ValidateAdd">
                      <b-row>
                          <b-col md="2">
                              <b-form-group label="Codigo:">
                              <b-form-input type="text" class="text-center" readonly v-model="newcash.cash_number" ></b-form-input>
                              <small v-if="newerrors.id_correlative" class="form-text text-danger">Ingrese un código</small >
                              </b-form-group>
                          </b-col>

                          <b-col md="2">
                              <b-form-group label="Fecha Inicio:">
                              <b-form-input type="text" class="text-center" readonly v-model="newcash.start_date" ></b-form-input>
                              </b-form-group>
                          </b-col>

                          <b-col md="2">
                              <b-form-group label="Saldo Inicial (Soles) :">
                              <b-form-input type="number" step="any" class="text-right" v-model="newcash.initial_balance_pen" ></b-form-input>
                              <small v-if="newerrors.initial_balance_pen" class="form-text text-danger">Ingrese un monto</small >
                              </b-form-group>
                          </b-col>

                          <b-col md="2">
                              <b-form-group label="Saldo Inicial (Dolares) :">
                              <b-form-input type="number" step="any" class="text-right" v-model="newcash.initial_balance_usd" ></b-form-input>
                              <small v-if="newerrors.initial_balance_usd" class="form-text text-danger">Ingrese un monto</small >
                              </b-form-group>
                          </b-col>

                          <b-col md="2">
                              <b-form-group label="Saldo Inicial (Pesos) :">
                              <b-form-input type="number" step="any" class="text-right" v-model="newcash.initial_balance_clp" ></b-form-input>
                              <small v-if="newerrors.initial_balance_clp" class="form-text text-danger">Ingrese un monto</small >
                              </b-form-group>
                          </b-col>

                          <b-col md="2">
                              <b-form-group label=".">
                              <b-button type="submit" class="form-control btn btn-primary" variant="primary">INICIAR</b-button>
                              </b-form-group>
                          </b-col>
                      </b-row>
                    </b-form>


                  
                </div>



                <div class="w-100" v-if="state == 2">

                    <b-form id="Form" @submit.prevent="Validate">
                            <b-row>
                                <b-col md="3">
                                    <b-form-group label="Codigo:">
                                    <b-form-input type="text" class="text-center" readonly v-model="cash.cash_number" ></b-form-input>
                                    <small v-if="errors.id_correlative" class="form-text text-danger">Ingrese un código</small >
                                    </b-form-group>
                                </b-col>

                                <b-col md="3">
                                    <b-form-group label="Fecha Inicio:">
                                    <b-form-input type="text" class="text-center" readonly v-model="cash.start_date" ></b-form-input>
                                    </b-form-group>
                                </b-col>

                                <b-col md="3">
                                    <b-form-group label="Fecha Final:">
                                    <b-form-input type="text" class="text-center" readonly v-model="cash.start_date" ></b-form-input>
                                    </b-form-group>
                                </b-col>

                                <b-col md="3">
                                    <b-form-group label="Usuario:">
                                    <b-form-input type="text" class="text-center" readonly v-model="cash.email" ></b-form-input>
                                    </b-form-group>
                                </b-col>

                                <b-col md="12">
                                  <b-form-group>
                                    <label class="control-label">Observación: </label>
                                    <b-form-textarea v-model="cash.observation" rows="2"></b-form-textarea>                 
                                  </b-form-group>
                                </b-col>


                                <b-col md="12"> <br><br> </b-col>

                                <b-col md="12">
                                  <div class="table-responsive">
                                    <table class="table table-striped table-bordered w-100">
                                      <thead>
                                          <tr class="bg-danger">
                                            <td class="text-center" width="40%" ></td>
                                            <td class="text-center" width="20%">SOLES</td>
                                            <td class="text-center" width="20%">DOLARES</td>
                                            <td class="text-center" width="20%">PESOS</td>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr class="bg-success">
                                            <td class="text-let" >Saldo Inicial</td>
                                            <td class="text-right" >{{cash.initial_balance_pen}}</td>
                                            <td class="text-right" >{{cash.initial_balance_usd}}</td>
                                            <td class="text-right" >{{cash.initial_balance_clp}}</td>
                                          </tr>
                                          <tr class="bg-success">
                                            <td class="text-let" >Total de Ventas del Periodo</td>
                                            <td class="text-right" >{{cash.total_sales_period_pen}}</td>
                                            <td class="text-right" >{{cash.total_sales_period_usd}}</td>
                                            <td class="text-right" >{{cash.total_sales_period_clp}}</td>
                                          </tr>
                                          <tr class="bg-info">
                                            <td class="text-let pl-3" >Ventas al Contado</td>
                                            <td class="text-right" >{{cash.total_cash_sales_pen}}</td>
                                            <td class="text-right" >{{cash.total_cash_sales_usd}}</td>
                                            <td class="text-right" >{{cash.total_cash_sales_clp}}</td>
                                          </tr>
                                          <tr class="bg-light text-dark">
                                            <td class="text-let pl-5" >Efectivo</td>
                                            <td class="text-right" >{{cash.cash_pen}}</td>
                                            <td class="text-right" >{{cash.cash_usd}}</td>
                                            <td class="text-right" >{{cash.cash_clp}}</td>
                                          </tr>
                                          <tr class="bg-light text-dark">
                                            <td class="text-let pl-5" >Visa</td>
                                            <td class="text-right" >{{cash.visa_pen}}</td>
                                            <td class="text-right" >{{cash.visa_usd}}</td>
                                            <td class="text-right" >{{cash.visa_clp}}</td>
                                          </tr>
                                          <tr class="bg-light text-dark">
                                            <td class="text-let pl-5" >Mastercard</td>
                                            <td class="text-right" >{{cash.mastercard_pen}}</td>
                                            <td class="text-right" >{{cash.mastercard_usd}}</td>
                                            <td class="text-right" >{{cash.mastercard_clp}}</td>
                                          </tr>
                                          <tr class="bg-light text-dark">
                                            <td class="text-let pl-5" >American Express</td>
                                            <td class="text-right" >{{cash.american_express_pen}}</td>
                                            <td class="text-right" >{{cash.american_express_usd}}</td>
                                            <td class="text-right" >{{cash.american_express_clp}}</td>
                                          </tr>
                                          <tr class="bg-light text-dark">
                                            <td class="text-let pl-5" >Transferencia de Fondos</td>
                                            <td class="text-right" >{{cash.transfer_funds_pen}}</td>
                                            <td class="text-right" >{{cash.transfer_funds_usd}}</td>
                                            <td class="text-right" >{{cash.transfer_funds_clp}}</td>
                                          </tr>
                                          <tr class="bg-info">
                                            <td class="text-let pl-3" >Ventas al Credito</td>
                                            <td class="text-right" >{{cash.total_credit_sales_pen}}</td>
                                            <td class="text-right" >{{cash.total_credit_sales_usd}}</td>
                                            <td class="text-right" >{{cash.total_credit_sales_clp}}</td>
                                          </tr>
                                          <tr class="bg-success">
                                            <td class="text-let" >Ingresos Extraordinarios</td>
                                            <td class="text-right" >{{cash.total_extraordinary_income_pen}}</td>
                                            <td class="text-right" >{{cash.total_extraordinary_income_usd}}</td>
                                            <td class="text-right" >{{cash.total_extraordinary_income_clp}}</td>
                                          </tr>
                                          <tr class="bg-success">
                                            <td class="text-let" >Egresos Extraordinarios</td>
                                            <td class="text-right" >{{cash.total_extraordinary_expenses_pen}}</td>
                                            <td class="text-right" >{{cash.total_extraordinary_expenses_usd}}</td>
                                            <td class="text-right" >{{cash.total_extraordinary_expenses_clp}}</td>
                                          </tr>
                                          <tr class="bg-dark">
                                            <td class="text-let" >Saldo Final</td>
                                            <td class="text-right" >{{cash.total_income_pen}}</td>
                                            <td class="text-right" >{{cash.total_income_usd}}</td>
                                            <td class="text-right" >{{cash.total_income_clp}}</td>
                                          </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </b-col>

                          


                                <b-col md="2"></b-col>
                                <b-col md="2">
                                    <b-button type="button" @click="ExportPDF" class="form-control btn btn-primary" variant="info">PDF</b-button>
                                </b-col>
                                <b-col md="2">
                                    <b-button type="button" @click="ExportExcel" class="form-control btn btn-primary" variant="success">EXCEL</b-button>
                                </b-col>
                                <b-col md="2">
                                    <b-button type="submit" class="form-control btn btn-primary" variant="primary">CERRAR CAJA</b-button>
                                </b-col>
                            </b-row>
                      </b-form>

                </div>

        
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
var moment = require("moment");


export default {
  name: "CashAdd",
  components:{
  },
  data() {
    return {
      module: "Cash",
      role: 2,
      state: 0,

      //new
      newcash: {
          id_cash:0,
          id_user:0,
          id_correlative:0,
          cash_number:'',
          start_date:moment(new Date()).local().format("YYYY-MM-DD") +' '+ moment(new Date()).local().format("H:m:s"),
          end_date:'',
          initial_balance_pen:(0).toFixed(2),
          initial_balance_usd:(0).toFixed(2),
          initial_balance_clp:(0).toFixed(2),
          observation:'',
          state:0,
      },
      newerrors: {
        id_correlative: false,
        initial_balance_pen: false,
        initial_balance_usd: false,
        initial_balance_clp: false,
      },
      validate_add: false,







      cash: {
          id_cash:0,
          id_user:0,
          id_correlative:0,
          cash_number:'',
          start_date:'',
          end_date:'',
          initial_balance_pen:(0).toFixed(2),
          cash_pen:(0).toFixed(2),
          card_pen:(0).toFixed(2),
          web_way_pen:(0).toFixed(2),
          transfer_funds_pen:(0).toFixed(2),
          total_cash_sales_pen:(0).toFixed(2),
          total_credit_sales_pen:(0).toFixed(2),
          total_sales_period_pen:(0).toFixed(2),
          total_collection_credits_pen:(0).toFixed(2),
          total_extraordinary_income_pen:(0).toFixed(2),
          total_extraordinary_expenses_pen:(0).toFixed(2),
          total_income_pen:(0).toFixed(2),
          initial_balance_usd:(0).toFixed(2),
          cash_usd:(0).toFixed(2),
          transfer_funds_usd:(0).toFixed(2),
          web_way_usd:(0).toFixed(2),
          web_way_usd:(0).toFixed(2),
          total_cash_sales_usd:(0).toFixed(2),
          total_credit_sales_usd:(0).toFixed(2),
          total_sales_period_usd:(0).toFixed(2),
          total_collection_credits_usd:(0).toFixed(2),
          total_extraordinary_income_usd:(0).toFixed(2),
          total_extraordinary_expenses_usd:(0).toFixed(2),
          total_income_usd:(0).toFixed(2),
          initial_balance_clp:(0).toFixed(2),
          cash_clp:(0).toFixed(2),
          transfer_funds_clp:(0).toFixed(2),
          web_way_clp:(0).toFixed(2),
          web_way_clp:(0).toFixed(2),
          total_cash_sales_clp:(0).toFixed(2),
          total_credit_sales_clp:(0).toFixed(2),
          total_sales_period_clp:(0).toFixed(2),
          total_collection_credits_clp:(0).toFixed(2),
          total_extraordinary_income_clp:(0).toFixed(2),
          total_extraordinary_expenses_clp:(0).toFixed(2),
          total_income_clp:(0).toFixed(2),
          observation:'',
          state:0,
          

      },

      //errors
      errors: {
        id_correlative: false,
        initial_balance_pen: false,
        initial_balance_usd: false,
        initial_balance_clp: false,
      },
      
      validate: false,


    };
  },
  mounted() {
    this.ValidateOpenBox();
  },
  methods: {
    ValidateOpenBox,

    GetCorrelative,
    Addcash,
    ValidateAdd,
    ExportExcel,
    ExportPDF,

    GetActiveCash,
    CalculateCash,
    Closecash,
    Validate,

  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function ExportExcel() {

  let me = this;
  let url = this.url_base + "cash-export-excel/"+this.cash.id_cash+'/1';
  
  window.open(url,'_blank');
}

function ExportPDF() {

  let me = this;
  let url = this.url_base + "cash-export-pdf/"+this.cash.id_cash+'/1';
  
  window.open(url,'_blank');
}

function ValidateOpenBox() {
  let me = this;
  let url = this.url_base + "cash/validate-open-box/"+this.user.id_user;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.state = 2;
        me.GetActiveCash();
      }else{
        me.state = 1;
        me.GetCorrelative();

      }
    })
}






////STATE 1
function GetCorrelative() {
  let me = this;
  let url = this.url_base + "cash/correlative";

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.newcash.id_correlative = response.data.result.id_correlative;
        me.newcash.cash_number = response.data.result.number;
      } 
    })
}

function Addcash(_this) {

  
  let me = _this;
  me.newcash.id_user = me.user.id_user;
  let url = me.url_base + "cash/add";
  let data = me.newcash;

  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {
      "Content-Type": "application/json",
      token: me.token,
      module: me.module,
      role: me.role
    },
  })
    .then(function (response) {
      if (response.data.status == 201) {
        Swal.fire("Sistema", "Se ha abierto la cuena caja", "success");
        me.state = 2;
        me.GetActiveCash();
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function ValidateAdd() {
  this.newerrors.id_correlative = this.newcash.id_correlative.length == 0 ? true : false;
  this.newerrors.initial_balance_pen = this.newcash.initial_balance_pen.length == 0 ? true : false;
  this.newerrors.initial_balance_usd = this.newcash.initial_balance_usd.length == 0 ? true : false;
  this.newerrors.initial_balance_clp = this.newcash.initial_balance_clp.length == 0 ? true : false;

  if (this.newerrors.id_correlative) { this.validate_add = true; return false;}else{ this.validate_add = false; }
  if (this.newerrors.initial_balance_pen) { this.validate_add = true; return false;}else{ this.validate_add = false; }
  if (this.newerrors.initial_balance_usd) { this.validate_add = true; return false;}else{ this.validate_add = false; }
  if (this.newerrors.initial_balance_clp) { this.validate_add = true; return false;}else{ this.validate_add = false; }

  let me = this;
  Swal.fire({
    title: "Esta seguro de inicar caja ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.Addcash(me);
    }
  });

}





// STATE 2


//listar usuario
function GetActiveCash() {
  let me = this;
  let url = this.url_base + "cash/get-active-cash/"+this.user.id_user;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        
          me.cash.id_cash = response.data.result.id_cash;
          me.cash.id_user = response.data.result.id_user;
          me.cash.id_correlative = response.data.result.id_correlative;
          me.cash.cash_number = response.data.result.cash_number;
          me.cash.start_date = response.data.result.start_date;
          me.cash.end_date = moment(new Date()).local().format("YYYY-MM-DD") +' '+ moment(new Date()).local().format("H:m:s");

          me.cash.observation = response.data.result.observation;
          me.cash.state = response.data.result.state;
          me.cash.email = response.data.result.email;

          me.CalculateCash(response.data.result.id_cash);
          

      } 
    })
}

function CalculateCash(id_cash) {
  let me = this;
  let url = this.url_base + "cash/calculate_cash/"+id_cash;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        
          me.cash.initial_balance_pen = response.data.result.initial_balance_pen;
          me.cash.cash_pen = response.data.result.cash_pen;
          me.cash.visa_pen = response.data.result.visa_pen;
          me.cash.mastercard_pen = response.data.result.mastercard_pen;
          me.cash.american_express_pen = response.data.result.american_express_pen;
          me.cash.transfer_funds_pen = response.data.result.transfer_funds_pen;
          me.cash.account_deposit_pen = response.data.result.account_deposit_pen;
          me.cash.total_cash_sales_pen = response.data.result.total_cash_sales_pen;
          me.cash.total_credit_sales_pen = response.data.result.total_credit_sales_pen;
          me.cash.total_sales_period_pen = response.data.result.total_sales_period_pen;
          me.cash.total_collection_credits_pen = response.data.result.total_collection_credits_pen;
          me.cash.total_extraordinary_income_pen = response.data.result.total_extraordinary_income_pen;
          me.cash.total_extraordinary_expenses_pen = response.data.result.total_extraordinary_expenses_pen;
          me.cash.total_income_pen = response.data.result.total_income_pen;
          me.cash.initial_balance_usd = response.data.result.initial_balance_usd;
          me.cash.cash_usd = response.data.result.cash_usd;
          me.cash.visa_usd = response.data.result.visa_usd;
          me.cash.mastercard_usd = response.data.result.mastercard_usd;
          me.cash.american_express_usd = response.data.result.american_express_usd;
          me.cash.transfer_funds_usd = response.data.result.transfer_funds_usd;
          me.cash.account_deposit_usd = response.data.result.account_deposit_usd;
          me.cash.total_cash_sales_usd = response.data.result.total_cash_sales_usd;
          me.cash.total_credit_sales_usd = response.data.result.total_credit_sales_usd;
          me.cash.total_sales_period_usd = response.data.result.total_sales_period_usd;
          me.cash.total_collection_credits_usd = response.data.result.total_collection_credits_usd;
          me.cash.total_extraordinary_income_usd = response.data.result.total_extraordinary_income_usd;
          me.cash.total_extraordinary_expenses_usd = response.data.result.total_extraordinary_expenses_usd;
          me.cash.total_income_usd = response.data.result.total_income_usd;
          me.cash.initial_balance_clp = response.data.result.initial_balance_clp;
          me.cash.cash_clp = response.data.result.cash_clp;
          me.cash.visa_clp = response.data.result.visa_clp;
          me.cash.mastercard_clp = response.data.result.mastercard_clp;
          me.cash.american_express_clp = response.data.result.american_express_clp;
          me.cash.transfer_funds_clp = response.data.result.transfer_funds_clp;
          me.cash.account_deposit_clp = response.data.result.account_deposit_clp;
          me.cash.total_cash_sales_clp = response.data.result.total_cash_sales_clp;
          me.cash.total_credit_sales_clp = response.data.result.total_credit_sales_clp;
          me.cash.total_sales_period_clp = response.data.result.total_sales_period_clp;
          me.cash.total_collection_credits_clp = response.data.result.total_collection_credits_clp;
          me.cash.total_extraordinary_income_clp = response.data.result.total_extraordinary_income_clp;
          me.cash.total_extraordinary_expenses_clp = response.data.result.total_extraordinary_expenses_clp;
          me.cash.total_income_clp = response.data.result.total_income_clp;
  

      } 
    })
}

function Closecash(_this) {
  // validacion de campos obligatorios
  let me = _this;
  me.Validate();
  if (me.validate) {
    return false;
  }

  me.cash.id_user = me.user.id_user;

  
  let url = me.url_base + "cash/close";
  let data = me.cash;

  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {
      "Content-Type": "application/json",
      token: me.token,
      module: me.module,
      role: me.role
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        Swal.fire("Sistema", "Se ha Cerrado la caja", "success");
        me.state = 1;
        me.GetCorrelative();
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function Validate() {
  this.errors.id_cash = this.cash.id_cash.length == 0 ? true : false;

  if (this.errors.id_cash) { this.validate = true; return false;}else{ this.validate = false; }


  let me = this;

  Swal.fire({
    title: 'Esta seguro de cerrar la caja?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, Estoy de Acuerdo!'
  }).then((result) => {
    if (result.isConfirmed) {
      Closecash(me);
    }
  })


}
</script>
